import Layout from '@/components/layouts/Layout.vue'
export default [
  {
    path: '/video',
    component: Layout,
    children: [
      {
        path: ':videoId',
        name: 'videoShow',
        meta: {
          title: '视频详情',
          requiresAuth: true,
          keepAlive: true
        },
        component: () => import('@/views/video/video.vue')
      }
    ]
  }
]
