import LayoutView from '@/components/layouts/Layout.vue'

export default [
  {
    path: '/jd',
    component: LayoutView,
    children: [
      {
        path: 'index',
        name: 'JdIndex',
        meta: {
          title: '京东专营'
        },
        component: () => import('@/views/jd/index.vue')
      },
      {
        path: 'goods',
        name: 'JdGoodsList',
        meta: {
          title: '京东商品'
        },
        component: () => import('@/views/jd/lists/Lists.vue')
      },
      {
        path: 'goods/:spuId(\\d+)/:skuId(\\d+)',
        name: 'JdGoods',
        meta: {
          title: '京东商品详情'
        },
        component: () => import('@/views/jd/goods/Show.vue')
      },
      {
        path: 'goodsBuy',
        name: 'JdBuy',
        meta: {
          title: '立即购买'
        },
        component: () => import('@/views/jd/goods/buy.vue')
      },
      {
        path: 'goods/:orderId',
        name: 'JdDetail',
        meta: {
          title: '京东订单详情'
        },
        component: () => import('@/views/jd/goods/detail.vue')
      },
      {
        path: 'RefundsInfo',
        name: 'JdRefundsInfo',
        meta: {
          title: '售后详情'
        },
        component: () => import('@/views/jd/refunds/jd_refunds_info.vue')
      },
      {
        path: 'RefundApply',
        name: 'JdRefundApply',
        meta: {
          title: '售后详情'
        },
        component: () => import('@/views/jd/refunds/jd_refunds_apply.vue')
      }
    ]
  }
]
