
import LayoutView from '@/components/layouts/Layout.vue'

export default [
  {
    path: '/zone/:type',
    component: LayoutView,
    children: [
      {
        path: '/zone/:type',
        name: 'zoneIcbc',
        meta: {
          auth: true,
        },
        component: () => import('@/views/icbc/index.vue')
      }
    ]
  }
]
