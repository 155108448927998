
export default [
  {
    component: () => import('@/components/layouts/Layout.vue'),
    path: '/bigcustomer',
    children: [
      {
        path: '/bigcustomer',
        name: 'BigCustomerIndex',
        component: () => import('@/views/bigcustomer/index.vue'),
        meta: {
          title: '大客户专区',
          auth : true
        }
      },{
        path: '/bigcustomer/:id/created',
        name: 'BigCustomerCreat',
        component: () => import('@/views/bigcustomer/order.vue'),
        meta: {
          title: '创建订单',
          auth : true
        }
      }
    ]
  }
]
